//items

export const itemBlobs = [
  {
    viewBox: "0 0 200 200",
    d:
      "M63.3,-10.9C72.1,6.4,62.3,39.3,45.6,48.1C28.8,56.8,5.1,41.3,-18.1,24.5C-41.2,7.8,-63.6,-10.1,-60.5,-19.6C-57.4,-29,-28.7,-30.1,-0.7,-29.9C27.3,-29.6,54.6,-28.1,63.3,-10.9Z",
  },

  {
    viewBox: "0 0 200 200",
    d:
      "M68.4,-14.6C75.9,0.9,60.5,31.5,36.4,48.7C12.4,65.8,-20.4,69.5,-41.9,54.7C-63.3,39.8,-73.4,6.3,-64.5,-11.1C-55.7,-28.5,-27.8,-29.9,1.3,-30.3C30.4,-30.7,60.9,-30.2,68.4,-14.6Z",
  },

  {
    viewBox: "0 0 200 200",
    d:
      "M55.7,-25.1C58.4,-9.7,37.4,6.4,16.3,21.5C-4.8,36.5,-25.9,50.6,-38.2,43.8C-50.4,37,-53.8,9.2,-45.9,-13.3C-38.1,-35.7,-19.1,-52.9,3.7,-54.1C26.5,-55.3,53,-40.5,55.7,-25.1Z",
  },

  {
    viewBox: "0 0 200 200",
    d:
      "M38.3,-11.7C45.6,10.1,44.8,35.3,31,46.2C17.2,57.1,-9.6,53.6,-27.8,40C-46,26.3,-55.7,2.6,-49.6,-17.6C-43.5,-37.7,-21.8,-54.1,-3.1,-53.1C15.5,-52.1,31,-33.6,38.3,-11.7Z",
  },

  {
    viewBox: "0 0 200 200",
    d:
      "M68.1,-19.6C73.1,-6.8,51.5,17.2,27.8,33.2C4.2,49.2,-21.6,57.2,-39.5,46.1C-57.4,35,-67.4,4.9,-59.6,-11.9C-51.7,-28.7,-25.8,-32.3,2.9,-33.2C31.6,-34.1,63.1,-32.5,68.1,-19.6Z",
  },

  {
    viewBox: "0 0 200 200",
    d:
      "M38.4,-9.9C43.8,4.2,38.2,24.5,25.1,33.9C12,43.3,-8.6,41.7,-27.4,29.9C-46.3,18.1,-63.3,-4,-58.4,-17.4C-53.6,-30.7,-26.8,-35.4,-5.2,-33.7C16.5,-32,32.9,-24,38.4,-9.9Z",
  },

  {
    viewBox: "0 0 200 200",
    d:
      "M42.2,-12.4C49.6,8.9,46.8,34.8,28.5,50.9C10.1,67,-23.8,73.3,-37.6,61C-51.4,48.8,-45.1,18.1,-35.5,-6.3C-25.8,-30.8,-12.9,-49.1,2.3,-49.8C17.4,-50.5,34.9,-33.8,42.2,-12.4Z",
  },

  {
    viewBox: "0 0 200 200",
    d:
      "M47.5,-21C58.9,-7.8,63.4,15.7,54.4,29.9C45.3,44.1,22.7,48.9,4.8,46.1C-13,43.3,-26.1,33,-37,17.7C-48,2.4,-56.9,-17.8,-50.4,-28.2C-43.8,-38.6,-21.9,-39.2,-1.9,-38.1C18.1,-37,36.2,-34.2,47.5,-21Z",
  },

  {
    viewBox: "0 0 200 200",
    d:
      "M60.2,-40C66.7,-23.6,52.6,-0.7,39.2,15.5C25.7,31.7,12.9,41.1,2.3,39.8C-8.2,38.4,-16.4,26.3,-24.5,13.2C-32.6,0.2,-40.6,-13.9,-36.5,-29C-32.4,-44.1,-16.2,-60.1,5.4,-63.2C26.9,-66.3,53.8,-56.4,60.2,-40Z",
  },
];
